import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DevotionalManager from './DevotionalManager';
import OrganizationModule from './OrganizationModule'; // Import your Organization module component
import APIPromptComponent from './APIPromptComponent';
import Home from './Home'; // Import a Home component to navigate to different modules
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import ApplicationForm from './application/ApplicationForm';
import ApplicationsList from './application/ApplicationList';
import AppKeys from './application/AppKeys';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/devotionals" element={<DevotionalManager />} />
        <Route path="/organizations" element={<OrganizationModule />} />
        <Route path="/organizations/:orgId/prompts/:id" element={<APIPromptComponent />} />
        <Route path="/organizations/:orgId/prompts" element={<APIPromptComponent />} />
        <Route path="/applications" element={<ApplicationsList />} />
        <Route path="/applications/new" element={<ApplicationForm />} />
        <Route path="/applications/edit/:id" element={<ApplicationForm />} />
        <Route path="/applications/:accountId/keys" element={<AppKeys />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();