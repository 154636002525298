import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchAppKeys, createAccountKey, deleteAccountKey } from '../apiService';
import { Button, Form, Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const AppKeys = () => {
  const [keys, setKeys] = useState([]);
  const [name, setName] = useState('');
  const { accountId } = useParams();

  useEffect(() => {
    loadKeys();
  }, [accountId]);

  const loadKeys = async () => {
    const data = await fetchAppKeys(accountId);
    setKeys(data);
    setName(GenerateUUID())
  };

  const addKey = async (event) => {
    event.preventDefault();
    const key = { name };
    await createAccountKey(accountId, key);
    setName('');
    loadKeys();
  };

  const deleteKey = async (id) => {
    await deleteAccountKey(accountId, id);
    loadKeys();
  };

    const GenerateUUID = () => {
    
      return uuidv4().replace(/-/g, '');
    };

  return (
    <div>
      <h2>App Keys</h2>
      <Form onSubmit={addKey}>
        <Form.Group controlId="formKeyName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </Form.Group>
        <Button variant="primary" type="submit">Add Key</Button>
      </Form>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Key</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.map(key => (
            <tr key={key.id}>
              <td>{key.id}</td>
              <td>{key.name}</td>
              <td>
                <Button variant="danger" onClick={() => deleteKey(key.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AppKeys;
