import React, { useEffect, useState } from 'react';
import { login, fetchOrgs, createOrg, updateOrg, deleteOrg } from './apiService';
import { Button, Form, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OrgComponent = () => {
  const [organizations, setOrganizations] = useState([]);
  const [error, setError] = useState(null);
  const [editId, setEditId] = useState(null); // Store ID of the org being edited
  const [newOrgName, setNewOrgName] = useState(''); // Store new org name for the creation form
  const [form, setForm] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    const loginAndFetchOrgs = async () => {
      try {
        // Log in and save the JWT token
        const loginData = await login();
        localStorage.setItem('jwtToken', loginData.token);

        // Fetch the organizations
        const orgsData = await fetchOrgs();
        setOrganizations(orgsData.organizations);
      } catch (error) {
        console.error('Error during login or fetching organizations:', error);
        setError(error);
      }
    };

    loginAndFetchOrgs();
  }, []);

  const handleEdit = (organization) => {
    setForm({
      id: organization.id,
      name: organization.name
    });
  };

  const handleCreateOrg = async (orgData) => {
    try {
      const newOrg = await createOrg(orgData);
      setOrganizations([...organizations, newOrg]);
    } catch (error) {
      console.error('Error creating organization:', error);
      setError(error);
    }
  };

  const handleUpdateOrg = async (id, updatedData) => {
    try {
      const updatedOrg = await updateOrg(id, updatedData);
      const orgsData = await fetchOrgs();
      setOrganizations(orgsData.organizations);
    } catch (error) {
      console.error('Error updating organization:', error);
      setError(error);
    }
  };

  const handleDeleteOrg = async (id) => {
    try {
      await deleteOrg(id);
      const orgsData = await fetchOrgs();
      setOrganizations(orgsData.organizations);
    } catch (error) {
      console.error('Error deleting organization:', error);
      setError(error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {

      const data = {
        ...form
      }

      if (form.id) { 
          handleUpdateOrg(form.id, data)
      } else {
          handleCreateOrg(data)
      }

      console.log(`set the org ${form.name}`);

      setForm({
        id:'',
        name:''
      })



      const orgsData = await fetchOrgs();
      setOrganizations(orgsData.organizations);

      console.log(`set the org ${form.name}`);

    } catch (error) {
      console.error('Error saving devotional:', error);
    }
  };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };


  return (


    <div>

      <form onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={form.id} />

        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" className="form-control" id="name" name="name" value={form.name} onChange={handleInputChange} required />
        </div>
        <button type="submit" className="btn btn-primary">{form.id ? 'Update' : 'Add'} Organization</button>
        </form>


      <h1>Organizations</h1>
      {error && <p className="text-danger">{error.message}</p>}
      <ul className="list-group">
        {organizations.map(org => (
          <li key={org.id} className="list-group-item">
             <div className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{org.id} - {org.name}</strong>
              </div>
              <div>
                <Link to={`/organizations/${org.id}/prompts`} className="btn btn-info btn-sm ml-2">
                  View Prompts
                </Link>
                <button className="btn btn-secondary btn-sm mr-2" onClick={() => handleEdit(org)}>Edit</button>
                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteOrg(org.id)}>Delete</button>
              </div>
            </div>
          </li>
        ))}
      </ul>
     
    </div>
  );
};

export default OrgComponent;