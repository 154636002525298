import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { updateApplication, createApplication, getApplication } from '../apiService';

const ApplicationForm = () => {
  const [name, setName] = useState('');
  const [organizationId, setOrgId] = useState(5);

  const { id } = useParams();
  const history = useNavigate();

  useEffect(() => {
    if (id) {
      loadApp();
    }
  }, [id]);

  const loadApp = async () => {
    const app = await getApplication(id);
    setName(app.name);
  };

  const saveApp = async (event) => {
    event.preventDefault();
    const app = { name, organizationId};
    if (id) {
      await updateApplication(id, app);
    } else {
      await createApplication(app);
    }
    history('/applications');
  };

  return (
    <div>
      <h2>{id ? 'Edit Application' : 'Add Application'}</h2>
      <Form onSubmit={saveApp}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          <Form.Label>Org ID</Form.Label>
          <Form.Control type="number" value={organizationId} onChange={(e) => setOrgId(e.target.value)} required />
        </Form.Group>
        <Button variant="primary" type="submit">Save</Button>
      </Form>
    </div>
  );
};

export default ApplicationForm;
