import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { fetchApplications, deleteApplication, login } from '../apiService';

const ApplicationsList = () => {
  const [applications, setApplications] = useState([]);
 
  useEffect(() => {
    loadApp();
  }, []);

  const loadApp = async () => {

    const loginData = await login();
    localStorage.setItem('jwtToken', loginData.token);

    const data = await fetchApplications();
    setApplications(data);
  };

  const deleteApp = async (id) => {
    await deleteApplication(id);
    loadApp();
    
  };

  return (
    <div>
      <h2>Applications</h2>
      <Link to="/applications/new" className="btn btn-primary mb-3">Add Application</Link>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications.map(application => (
            <tr key={application.id}>
              <td>{application.id}</td>
              <td>{application.name}</td>
              <td>
                <Link to={`/applications/edit/${application.id}`} className="btn btn-warning mr-2">Edit</Link>
                <Button variant="danger" onClick={() => deleteApp(application.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ApplicationsList;
