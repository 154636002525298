import axios from 'axios';

// Create a base instance of Axios
const apiClient = axios.create({
  baseURL: 'https://api.faithnavigator.org',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add an interceptor to set the Authorization header for all requests if the token exists
apiClient.interceptors.request.use(config => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, error => Promise.reject(error));


  // Export individual functions to interact with endpoints
export const login = async () => {
    const response = await apiClient.get('/login');
    return response.data;
  };


  export const fetchOrgs = async () => {
    const response = await apiClient.get('/organizations');
    return response.data;
  };

  // Add more functions as needed
export const createOrg = async (organizationData) => {
    const response = await apiClient.post('/organizations', organizationData);
    return response.data;
  };
  
  export const updateOrg = async (id, updatedData) => {
    const response = await apiClient.put(`/organizations/${id}`, updatedData);
    return response.data;
  };
  
  export const deleteOrg = async (id) => {
    const response = await apiClient.delete(`/organizations/${id}`);
    return response.data;
  };

  export const fetchPrompts = async (organizationId) => {
    const response = await apiClient.get(`/organizations/${organizationId}/prompts`);
    return response.data;
  };

  export const createPrompt = async(organizationId, promptData) => {
    const response = await apiClient.post(`/organizations/${organizationId}`, promptData);
    const data = await response.json();
    return data;
  };

  export const updatePrompt = async (id, organizationId, updatedData) => {
    const response = await apiClient.put(`/organizations/${organizationId}/prompts/${id}`, updatedData);
    const data = await response.json();
    return data;
    
  };

  export const deletePrompt = async (id, organizationId) => {
    const response = await apiClient.delete(`/organizations/${organizationId}/prompts/${id}`);
    const data = await response.json();
    return data;
  };

  export const fetchApplications = async () => {
    const response = await apiClient.get(`/applications`);
    return response.data;
  };

  export const getApplication = async (id) => {
    const response = await apiClient.get(`/applications/${id}`);
    return response.data;
  };

  export const createApplication = async (accountData) => {
    const response = await apiClient.post('/applications', accountData);
    return response.data;
  };
 
  export const updateApplication = async (id, updatedData) => {
    const response = await apiClient.put(`/applications/${id}`, updatedData);
    return response.data;
  };

  export const deleteApplication = async (id) => {
    const response = await apiClient.delete(`/applications/${id}`);
    return response.data;
  };

  export const fetchAppKeys = async (appId) => {
    const response = await apiClient.get(`/applications/${appId}/keys`);
    return response.data;
  };

  export const createAccountKey = async (appId, key) => {
    const response = await apiClient.post(`/applications/${appId}/keys`, key);
    return response.data;
  };
  
  export const deleteAccountKey = async (appId, id) => {
    const response = await apiClient.delete(`/applications/${appId}/keys/${id}`);
    return response.data;
  };

  