import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { login, fetchPrompts, createPrompt, updatePrompt, deletePrompt } from './apiService';
import { Button, Form, FormLabel } from 'react-bootstrap';

const APIPromptComponent = () => {
  const { orgId } = useParams();
  const [prompts, setPrompts] = useState([]);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({
    id: '',
    organizationId: orgId,
    promptType: '',
    promptText: '',
  });

  useEffect(() => {
    const loginAndFetchPrompts = async () => {
      try {
        const loginData = await login();
        localStorage.setItem('jwtToken', loginData.token);

        const promptsData = await fetchPrompts(orgId);
        
        setPrompts(promptsData.prompts);
      } catch (error) {
        console.error('Error during login or fetching prompts:', error);
        setError(error);
      }
    };

    loginAndFetchPrompts();
  }, [orgId]);

  const handleEdit = (prompt) => {
    setForm({
      id: prompt.id,
      organizationId: prompt.organizationId,
      promptType: prompt.promptType,
      promptText: prompt.promptText,
    });
  };

  const handleCreatePrompt = async (promptData) => {
    try {
      const newPrompt = await createPrompt(orgId, promptData);
      setPrompts([...prompts, newPrompt]);
    } catch (error) {
      console.error('Error creating prompt:', error);
      setError(error);
    }
  };

  const handleUpdatePrompt = async (id, updatedData) => {
    try {
      const updatedPrompt = await updatePrompt(id, orgId, updatedData);
      const promptsData = await fetchPrompts(orgId);
      setPrompts(promptsData.prompts);
    } catch (error) {
      console.error('Error updating prompt:', error);
      setError(error);
    }
  };

  const handleDeletePrompt = async (id) => {
    try {
      await deletePrompt(id, orgId);
      const promptsData = await fetchPrompts(orgId);
      setPrompts(promptsData.prompts);
    } catch (error) {
      console.error('Error deleting prompt:', error);
      setError(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { ...form };

      if (form.id) {
        handleUpdatePrompt(form.id, data);
      } else {
        handleCreatePrompt(data);
      }

      setForm({
        id: '',
        organizationId: orgId,
        promptType: '',
        promptText: '',
      });

      const promptsData = await fetchPrompts(orgId);
      setPrompts(promptsData.prompts);
    } catch (error) {
      console.error('Error saving prompt:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={form.id} />
        <input type="hidden" name="organizationId" value={form.organizationId} />

        <div className="form-group">
          <label htmlFor="promptType">Prompt Type:</label>
          <input
            type="text"
            className="form-control"
            id="promptType"
            name="promptType"
            value={form.promptType}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="promptText">Prompt Text:</label>
          <textarea
            className="form-control"
            id="promptText"
            name="promptText"
            value={form.promptText}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          {form.id ? 'Update' : 'Add'} Prompt
        </button>
      </form>

      <h1>API Prompts</h1>
      {error && <p className="text-danger">{error.message}</p>}
      <ul className="list-group">
        {prompts.map((prompt) => (
          <li key={prompt.id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{prompt.promptType}</strong>
                <p>{prompt.promptText}</p>
              </div>
              <div>
                <button
                  className="btn btn-secondary btn-sm mr-2"
                  onClick={() => handleEdit(prompt)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDeletePrompt(prompt.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default APIPromptComponent;
